// library to setup userpilot.
import { Userpilot } from 'userpilot'
Userpilot.initialize('NX-29789007')

export function setUserPilot(account, user) {
  Userpilot.identify(user.email, {
    name: user.first_name + ' ' + user.last_name,
    email: user.email,
    account: {
      id: account.id,
      name: account.name,
    },
  })
}
