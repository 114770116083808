import { useFeatureStore } from '@app/stores/FeatureStore'
import { defineStore } from 'pinia'
import { getUser, updateAccountUser, getUsers, postUser } from '@app/api'
import * as amplitude from '@amplitude/analytics-browser'

export const useUserStore = defineStore('UserStore', {
  state: () => {
    return {
      user: null, // the user object represents the currently logged in user
      true_user: null, // the true_user object represents the impersonated user
    }
  },
  getters: {
    loggedInUser(state) {
      return () => {
        return state.user
      }
    },
    impersonatedUser(state) {
      return () => {
        return state.true_user
      }
    },
  },
  actions: {
    setUser(user) {
      this.user = user
      amplitude.setUserId(this.user?.email)
      useFeatureStore().setFeatures(this.user?.flipper_features)
    },
    setTrueUser(true_user) {
      this.true_user = true_user
    },
    // Fetches a single user by id
    // Only a logged in user with the role of Editor can fetch
    // user (from its own account) information
    fetchUser(userId) {
      return getUser(userId)
    },
    // Updates an user that is under the current user's (editor) account
    // Only a logged in user with the role of Editor can make this call
    updateAccountUser(user) {
      return updateAccountUser(user)
    },
    getUsers() {
      return getUsers()
    },
    postUser(user) {
      return postUser(user)
    },
  },
})
