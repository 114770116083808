// Houses common functionality about all assets

export function assetPath(asset) {
  switch (asset.type) {
    case 'AssetList':
      return `/app/asset_lists/${asset.id}`
    case 'RenderableTestimonial':
      return `/app/testimonials/${asset.id}`
    case 'MatrixxChart':
      return `/app/matrixx_charts/${asset.id}`
    case 'CustomerSpotlight':
      return `/app/customer_spotlights/${asset.id}`
    case 'VideoLinkAsset':
      return `/app/video_link_assets/${asset.id}`
    case 'FileAsset':
      return `/app/file_assets/${asset.id}`
    default:
      return `/app/content_assets/${asset.id}`
  }
}
