<template>
  <div class="basic_style_asset file-asset" :class="{ as_list: renderAsList }">
    <UercRibbon v-if="isUercSurvey" />
    <div class="wrapper">
      <div class="header d-flex flex-row align-items-center">
        <i class="bi bi-file-richtext" />
        <span>PDF</span>
      </div>
      <div v-if="fileAsset.title" class="title mt-2">{{ fileAsset.title }}</div>
      <div v-if="fileAsset.description" class="description mt-1">{{ fileAsset.description }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import UercRibbon from '@app/graphics/UercRibbon.vue'

export default {
  name: 'FileAsset',
  components: { UercRibbon },
  props: {
    fileAsset: { type: Object, required: true },
    // Toggle to check if the FileAsset should be rendered in a "List" context
    renderAsList: { type: Boolean, required: false, default: false },
  },
  computed: {
    isUercSurvey() {
      return this.fileAsset?.tag_list?.includes('Research')
    },
  },
}
</script>

<style lang="sass" scoped>
.file-asset
  width: 100%
  padding-bottom: 24px
  word-break: break-word
  border: 1px solid hsl(200, 24%, 92%)
  border-radius: 1.5em
  background: white

  .header
    gap: 0.5em
    font-weight: $font-weight-bold
    color: $uePurple
    .bi
      font-size: 1.2em
    span
      font-size: 0.8em

.title
  color: rgba(33, 37, 41, 1)
  font-weight: 700
  font-size: 1.3em

.description
  color: rgba(108, 116, 125, 1)
  font-weight: 400
  font-size: 0.875em

.as_list
  &:hover
    border: 1px solid $uePurple
  .title
    font-size: 1em
  .description
    font-size: 0.8em
</style>
