<template>
  <div v-if="contentAssets.length > 0" class="assets">
    <draggable
      class="row"
      v-model="sortableAssets"
      handle=".handle"
      @end="handleDropped"
      v-if="withReordering"
    >
      <div
        class="col-xl-4 col-lg-6"
        v-for="(asset, index) in sortableAssets"
        :key="asset.identifier"
      >
        <div class="asset">
          <router-link :key="asset.id" :to="getAppAssetUrl(asset)">
            <GridAssetPresenter :asset="asset" :account="account" @click="handleAssetClicked" />
          </router-link>
          <div class="controls d-flex mt-1">
            <div class="share" v-if="withShareButton">
              <div @click="toPublicAsset(asset)">
                <i class="bi bi-share h6" />
              </div>
            </div>

            <div class="right-controls">
              <div
                v-b-tooltip.hover.bottom="'Send to bottom'"
                class="send-to-bottom d-flex justify-content-center align-items-center"
                v-if="index !== sortableAssets.length - 1"
                @click="sendToBottom(asset)"
              >
                <i class="bi bi-arrow-bar-down"></i>
              </div>
              <div
                v-b-tooltip.hover.bottom="'Send to top'"
                v-if="asset.position !== 1"
                class="send-to-top d-flex justify-content-center align-items-center"
                @click="sendToTop(asset)"
              >
                <i class="bi bi-arrow-bar-up"></i>
              </div>
              <div class="handle"><GrabIcon /></div>
            </div>
          </div>
        </div>
      </div>
    </draggable>

    <div v-else>
      <masonry-wall :items="contentAssets" :column-width="350" :gap="16">
        <template #default="{ item }">
          <router-link :key="item.id" :to="assetUrl(item)">
            <div class="clicky">
              <GridAssetPresenter :asset="item" :account="account" @click="handleAssetClicked" />
            </div>
          </router-link>
        </template>
      </masonry-wall>
    </div>
  </div>
  <div v-else class="assets none">
    <h3>No assets match those filters.</h3>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import GrabIcon from '@app/graphics/GrabIcon.vue'
import { sortBy } from 'lodash'
import { reorderAssetListItem } from '@app/api'
import GridAssetPresenter from '@site/components/asset_lists/GridAssetPresenter.vue'
import { assetPath } from '@helpers/assets'

export default {
  components: {
    GridAssetPresenter,
    GrabIcon,
    draggable,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
    contentAssets: {
      type: Array,
      default: () => [],
    },
    withShareButton: {
      type: Boolean,
      default: false,
    },
    withReordering: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortableAssets: sortBy(JSON.parse(JSON.stringify(this.contentAssets)), 'position'),
    }
  },
  methods: {
    getAppAssetUrl(asset) {
      return assetPath(asset)
    },
    handleAssetClicked(asset) {
      this.$emit('asset-clicked', asset)
    },
    assetUrl(asset) {
      return `/assets/${asset.identifier}`
    },
    toPublicAsset(asset) {
      window.open(this.assetUrl(asset), '_blank')
      return
    },
    async handleDropped(e) {
      const newIndex = e.newIndex

      // the event argument does not have access to the item id
      // so we get it from the sortableAssets array
      this.sortableAssets[e.newIndex].position = newIndex + 1

      const itemId = this.sortableAssets[e.newIndex].asset_list_item_id

      const errored = reorderAssetListItem(itemId, newIndex + 1)
        .then((resp) => {
          this.updateArrayState(resp.data.asset_list_items_positions)
          this.$toast('Asset moved ✅')
          return false
        })
        .catch((_e) => {
          this.$toast('An error occured moving asset, please try again')
          return true
        })

      // Returning false will cancel the drag operation
      return !errored
    },
    async sendToTop(asset) {
      if (asset.position <= 1) return

      reorderAssetListItem(asset.asset_list_item_id, 1)
        .then((resp) => {
          this.updateArrayState(resp.data.asset_list_items_positions)
          this.$toast('Asset moved to the top ✅')
        })
        .catch((_e) => {
          this.$toast('An error occured moving asset, please try again')
        })
    },
    async sendToBottom(asset) {
      const positions = this.sortableAssets.map((e) => {
        return e['position']
      })
      const lastPosition = Math.max(...positions)

      if (asset.position == lastPosition) return

      reorderAssetListItem(asset.asset_list_item_id, lastPosition + 1)
        .then((resp) => {
          this.updateArrayState(resp.data.asset_list_items_positions)
          this.$toast('Asset moved to the bottom ✅')
        })
        .catch((_e) => {
          this.$toast('An error occured moving asset, please try again')
        })
    },
    updateArrayState(newAssetPositions) {
      // Uses the backend as the source of truth for the asset positions
      // and recreate the sortableAssets array based on the new positions

      // clone the current sortableAssets array
      let newSortableAssets = Object.assign([], this.sortableAssets)

      // update the asset positions based on the response from the backend
      newAssetPositions.forEach((e) => {
        const id = e[0]
        const position = e[1]
        const asset = newSortableAssets.find((a) => a.asset_list_item_id === id)
        asset.position = position
      })
      // replace the current sortableAssets array with the updated one
      this.sortableAssets = sortBy(newSortableAssets, 'position')
    },
  },
}
</script>
<style lang="sass" scoped>
.assets.none
  min-height: 35vh

.asset
  text-decoration: none
  display: inline-block
  width: 100% !important

.controls
  padding-left: 0.5em
  padding-right: 0.5em
  height: 30px
  .right-controls
    display: none
    gap: 1em
    float: right
    margin-left: auto

    .handle
      margin-left: auto
      cursor: grab
      color: grey

    .send-to-bottom, .send-to-top
      cursor: pointer
      color: grey
      margin-left: auto

  .share
    cursor: pointer
    display: none
    color: grey

.asset:hover
  text-decoration: none
  .controls
    .handle
      display: block !important
      &:hover
        color: black
    .share
      display: block !important
      &:hover
        color: black
    .right-controls
      display: flex !important
      &:hover
        color: black
</style>
