<template>
  <modal
    name="share_asset_modal"
    :scrollable="true"
    height="auto"
    :width="722"
    @before-open="beforeOpen"
  >
    <ShareTestimonialModalGuts
      v-if="content_asset && content_asset.type == 'RenderableTestimonial'"
      :content_asset="content_asset"
      :custom_themes="custom_themes"
    />
    <div class="modal_container" v-else-if="content_asset">
      <div class="modal_header">
        <h2>Share {{ asset_type }}</h2>
        <div class="closer" @click="$modal.hide('share_asset_modal')">
          <TimesIcon />
        </div>
      </div>
      <div class="modal_body">
        <p class="mb-0">
          Select the destination where you’ll be sharing the {{ asset_type.toLowerCase() }}, and
          you’ll receive optimized files for that platform.
        </p>
        <section>
          <div class="tiles platforms">
            <div class="tile" v-if="variant_map.linkedin">
              <div class="image">
                <img src="./graphics/logos/linkedin-logo.svg" />
              </div>
              <div class="info">
                <div class="brand">LinkedIn</div>
                <div class="whisper" v-if="variant_map.linkedin == 'generating'">Generating...</div>
                <a
                  class="downloader"
                  @click="onDownload"
                  :href="variant_map.linkedin"
                  target="_blank"
                  v-else
                >
                  Download
                </a>
              </div>
            </div>
            <div class="tile" v-if="variant_map.instagram">
              <div class="image">
                <img src="./graphics/logos/instagram-logo.svg" />
              </div>
              <div class="info">
                <div class="brand">Instagram</div>
                <div class="whisper" v-if="variant_map.instagram == 'generating'">
                  Generating...
                </div>
                <a class="downloader" @click="onDownload" :href="variant_map.instagram" v-else>
                  Download
                </a>
              </div>
            </div>
            <div class="tile" v-if="variant_map.twitter">
              <div class="image">
                <img src="./graphics/logos/twitter-logo.svg" />
              </div>
              <div class="info">
                <div class="brand">Twitter</div>
                <div class="whisper" v-if="variant_map.twitter == 'generating'">Generating...</div>
                <a class="downloader" @click="onDownload" :href="variant_map.twitter" v-else>
                  Download
                </a>
              </div>
            </div>
            <div class="tile" v-if="variant_map.facebook">
              <div class="image">
                <img src="./graphics/logos/facebook-logo.svg" />
              </div>
              <div class="info">
                <div class="brand">Facebook</div>
                <div class="whisper" v-if="variant_map.facebook == 'generating'">Generating...</div>
                <a class="downloader" @click="onDownload" :href="variant_map.facebook" v-else>
                  Download
                </a>
              </div>
            </div>
            <div class="tile" v-if="variant_map.powerpoint">
              <div class="image">
                <img src="./graphics/logos/powerpoint-logo.svg" />
              </div>
              <div class="info">
                <div class="brand">PowerPoint</div>
                <div class="whisper" v-if="variant_map.facebook == 'generating'">Generating...</div>
                <a
                  class="downloader"
                  @click="onDownload"
                  :href="variant_map.powerpoint"
                  target="_blank"
                  v-else
                >
                  Download
                </a>
              </div>
            </div>
          </div>
        </section>
        <section>
          <p class="mb-0">Or choose your preferred format.</p>
          <div class="tiles files">
            <div class="tile">
              <div class="image">
                <img :src="variant_map.left_image" />
              </div>
              <div class="info">
                <div class="brand mr-2">{{ variant_map.left_text }}</div>
                <div class="whisper" v-if="variant_map.left == 'generating'">Generating...</div>
                <a
                  class="downloader"
                  @click="onDownload"
                  :href="variant_map.left"
                  title="Download"
                  target="_blank"
                  v-else
                >
                  Download
                </a>
              </div>
            </div>
            <div class="tile" v-if="variant_map.right_image">
              <div class="image mb-2">
                <img :src="variant_map.right_image" />
              </div>
              <div class="info">
                <div class="brand mr-2">{{ variant_map.right_text }}</div>
                <div class="whisper" v-if="variant_map.right == 'generating'">Generating...</div>
                <a
                  class="downloader"
                  @click="onDownload"
                  :href="variant_map.right"
                  title="Download"
                  target="_blank"
                  v-else
                >
                  Download
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="modal_footer">
        <div class="left">
          <a href="#" @click="copyUrl()">
            <i class="bi bi-link" />
            Copy Asset URL
          </a>
          <a @click="copySnippet()" href="#" v-if="copyable_variant">
            <i class="bi bi-code" />
            Copy Embed Code
          </a>
        </div>
        <div class="right" v-if="content_asset.download_url">
          <a :href="content_asset.download_url">
            <i class="bi bi-download" />
            Download All Formats
          </a>
        </div>
      </div>
    </div>
  </modal>
</template>
<script lang="ts">
import TimesIcon from './graphics/TimesIcon.vue'
import ShareTestimonialModalGuts from './ShareTestimonialModalGuts.vue'
import { amplitudeTrack } from '@lib/amplitude'
import { pendoTrack } from '@lib/pendo'
import ahoy from 'ahoy.js'
import { assetEventData } from '@lib/tracking'
import verticalBarChartPreviewStyledTheme from '@images/content_assets/vertical_bar_chart_preview_styled_theme.png'
import verticalBarChartPreviewBasicTheme from '@images/content_assets/vertical_bar_chart_preview_basic_theme.png'
import statPreviewStyledTheme from '@images/content_assets/stat_preview_styled_theme.png'
import statPreviewBasicTheme from '@images/content_assets/stat_preview_basic_theme.png'
import multiPageSpotlight from '@images/content_assets/multi_page_spotlight.png'
import customerSpotlightPreviewImage from '@images/content_assets/customer_spotlight_preview_image.png'

export default {
  components: {
    TimesIcon,
    ShareTestimonialModalGuts,
  },
  // This `custom_themes` prop gets passed to the testimonial share modal. It should usually be undefined
  // because the themes will get fetched dynamically. At the time of writing, this prop is most useful for
  // testing purposes so that we can manipulate the theme options with less mocking.
  props: ['custom_themes'],
  data() {
    return {
      content_asset: null,
    }
  },
  computed: {
    variant_map() {
      if (this.content_asset.type == 'StatAsset') {
        return {
          linkedin: this.variantUrl('StatSocial191PngVariant'),
          instagram: this.variantUrl('StatSocial191PngVariant'),
          twitter: this.variantUrl('StatSocial191PngVariant'),
          facebook: this.variantUrl('StatSocial191PngVariant'),
          powerpoint: this.variantUrl('StatSocial191PngVariant'),
          left: this.variantUrl('StatSocial191PngVariant'),
          right: this.variantUrl('StatPngVariant'),
          left_text: 'Styled Theme',
          right_text: 'Basic Theme',
          left_image: statPreviewStyledTheme,
          right_image: statPreviewBasicTheme,
        }
      }
      if (this.content_asset.type == 'ChartAsset') {
        var basic_url = this.variantUrl('ChartPngVariant')
        // this will be null for horizontal charts
        var styled_url = this.variantUrl('ChartSocial11PngVariant')

        var left_image = styled_url
          ? verticalBarChartPreviewStyledTheme
          : verticalBarChartPreviewBasicTheme
        var right_image = styled_url ? verticalBarChartPreviewBasicTheme : null

        var left_text = styled_url ? 'Styled Theme' : 'Basic Theme'
        var right_text = styled_url ? 'Basic Theme' : null
        return {
          linkedin: styled_url || basic_url,
          instagram: styled_url || basic_url,
          twitter: styled_url || basic_url,
          facebook: styled_url || basic_url,
          powerpoint: styled_url || basic_url,
          left: styled_url || basic_url,
          right: basic_url,
          left_text: left_text,
          right_text: right_text,
          left_image: left_image,
          right_image: right_image,
        }
      }
      if (this.content_asset.type == 'SurveySpotlightAsset') {
        return {
          linkedin: this.variantUrl('SurveySpotlightMultiPagePdfVariant'),
          instagram: this.variantUrl('SurveySpotlightMultiPagePngVariant'),
          twitter: this.variantUrl('SurveySpotlightMultiPagePngVariant'),
          facebook: this.variantUrl('SurveySpotlightMultiPagePngVariant'),
          powerpoint: this.variantUrl('SurveySpotlightMultiPagePngVariant'),
          left: this.variantUrl('SurveySpotlightMultiPagePngVariant'),
          right: this.variantUrl('PdfVariant'),
          left_text: 'Zip File of PNGs',
          right_text: 'Full Page PDF',
          left_image: multiPageSpotlight,
          right_image: customerSpotlightPreviewImage,
        }
      }
      return {} // computed properties must return something
    },
    asset_type() {
      switch (this.content_asset.type) {
        case 'ChartAsset':
          return 'Chart'
        case 'StatAsset':
          return 'Stat'
        case 'RenderableTestimonial':
          return 'Testimonial'
        case 'CustomerSpotlight':
          return 'Customer Spotlight'
        case 'SurveySpotlightAsset':
          return 'Survey Spotlight'
        default:
          return null // computed properties must return something
      }
    },
    is_multipage_testimonial() {
      return this.content_asset.variants.find((v) => v.type.indexOf('MultiPage') > 0)
    },
    copyable_variant() {
      return this.content_asset.variants.find((v) =>
        ['TestimonialPngVariant', 'StatPngVariant', 'ChartPngVariant'].includes(v.type),
      )
    },
  },
  methods: {
    copyUrl() {
      navigator.clipboard.writeText(
        `${process.env.VUE_APP_UE_SHORT_URL}/${this.content_asset.identifier}`,
      )
      this.$toast('Asset URL copied to clipboard')
    },
    copySnippet() {
      var snippet = `<iframe src='https://app.userevidence.com/content_assets/${
        this.content_asset.identifier
      }/raw' width='${this.copyable_variant.width / 2}' height='${
        this.copyable_variant.height / 2
      }' frameBorder='0'></iframe>`
      navigator.clipboard.writeText(snippet)
      this.$toast('Embed Code copied to clipboard')
    },
    variant(variant_type) {
      return this.content_asset.variants.find((v) => v.type == variant_type)
    },
    variantUrl(variant_type) {
      var variant = this.content_asset.variants.find((v) => v.type == variant_type)
      if (variant == null) return

      if (variant.generating) return 'generating'

      var append = variant_type.indexOf('MultiPagePng') > 0 ? '.zip' : '?d='
      const url = variant ? variant.the_url + append : null
      return url
    },
    beforeOpen(e) {
      this.content_asset = e.params.content_asset
    },
    onDownload() {
      pendoTrack('download_asset', assetEventData(this.content_asset))
      amplitudeTrack('asset downloaded', assetEventData(this.content_asset))
      ahoy.track('asset downloaded', assetEventData(this.content_asset))
    },
  },
}
</script>
<style lang="sass" scoped>
// The first generation share modal had a fixed height of 690 but we changed the modal settings
// to auto height so that longer testimonials can be previewed. The following min-height is for
// backwards compatibility to prevent things from crunching that were dependent on that fixed height.
[data-modal="share_asset_modal"] + .vm--modal
  min-height: 690px
p
  margin-bottom: 20px
h5
  margin-bottom: 10px
  color: #6c7f89
section
  margin-bottom: 20px
.tiles
  display: flex
  justify-content: space-between
  .tile
    border-radius: 16px
    border: 1px solid #dfe8ec
    text-align: center
    width: 125px
    .image
      border-radius: 16px 16px 0 0
      background: #f2f6f7
      padding: 20px
      img
        width: 37%
    .info
      padding: 15px
      .brand
        margin-bottom: 6px
        font-size: 15px
    .downloader
      font-weight: 800
      font-size: 14px
      color: #48555b
      svg
        margin-left: 6px

.files
  .tile
    width: 49%
  .downloaders
    display: flex
    justify-content: space-around
.modal_footer
  padding-top: 20px
  // This is an emergency usage of !important
  // _modal.sass has a bunch of stinky styles that need to be removed/refactored so
  // that modals can go back to inheriting the Bootstrap defaults. There are a lot of
  // modals that will need to be adjusted as a result. Until that happens, the following
  // override fixes a glitch that was causing clipping / incorrect sizing of the modal.
  position: static!important
  a
    font-weight: 800
    font-size: 14px
    color: #48555b
    svg
      margin-right: 6px
  justify-content: space-between
  .left
    a
      margin-right: 12px
</style>
